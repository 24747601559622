.container {
  overflow-x: hidden;
  position: relative;
}

.container--header {
  z-index: 1;
}

.container--content {
  z-index: 0;
}

.container--overlays {
  z-index: 2;
}
