.card-home {
  color: $color-black;
  display: inline-block;
  height: 100%;
  margin: 0px;
  margin-left: -4px;
  position: relative;
  transition: background-color 600ms, width 600ms;
  white-space: initial;
  width: 320px;

  @include mobile-only {
    display: block;
    height: calc(100vh - 75px);
    margin-left: 0;
    scroll-snap-align: start;
    width: 100vw;
  }

  &:hover {
    background-color: $color-white;
    height: 100%;
    width: 540px;
  }

  &:hover .card-home__wrapper {
    background-color: $color-green;
    height: 100vh;
    width: 100%;
  }

  &:hover .card-home__content {
    height: 300px;
  }

  .card-home__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 500px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .icon-button__icon {
    display: inline-block;
    height: 24px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 24px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .card-home__wrapper {
    background-color: $color-yellow;
    background-image: url('images/0.jpg');
    background-position: 50% 50%;
    background-size: cover;
    bottom: 0px;
    height: 100%;
    position: absolute;
    transition: all 600ms;
    width: 100%;
  }

  .card-home__content {
    background-color: $color-white;
    bottom: 0px;
    cursor: pointer;
    height: 60px;
    left: 50%;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    transition: all 600ms;
    width: 280px;
  }

  .card-home__content .info-arrow {
    transition: transform 200ms;
  }

  .card-home__content:hover .info-arrow {
    transform: translateX(10px);
  }

  .card-home__format {
    border: 1px solid $color-black;
    border-radius: 20px;
    display: inline-block;
    font-size: 10px;
    letter-spacing: 2px;
    margin-bottom: 15px;
    padding: 3px 10px;
    text-transform: uppercase;
  }

  .card-home__title {
    @include typography(heading-6);

    margin-bottom: 15px;
  }

  .card-home__date {
    margin-bottom: 15px;
  }

  .card-home__text {
    margin-bottom: 15px;
  }

  .card-home__icon {
    background-image: url('images/arrow-right.png');
    background-size: cover;
    height: 16px;
    margin: 0 auto;
    width: 16px;
  }

  @include mobile-only {
    .card-home__wrapper {
      background-attachment: fixed;
    }

    .card-home__content {
      height: auto;
    }
  }
}
