.article-share-buttons {
  background-color: $color-white;
  padding: var(--margin) 0;
  position: relative;

  &__grid {
    display: flex;
    flex-flow: column nowrap;
    padding-left: var(--grid-gutter-edge);
    padding-right: var(--grid-gutter-edge);

    @include portrait {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__wrapper {
    margin-bottom: var(--grid-gutter-edge);

    @include portrait {
      margin-bottom: 0;
    }
  }

  &__sponsor {
    content: url('/static/images/sponsors/BofA-mobile.png');
    margin-top: var(--grid-gutter-edge);
    width: 200px;

    @include portrait {
      content: url('/static/images/sponsors/BofA-desktop.png');
      height: 100%;
      margin-top: 0;
    }

    @include landscape {
      width: 225px;
    }
  }

  &__title {
    padding-bottom: 5px;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
  }
}
