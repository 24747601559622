
.article-footer {
  margin: var(--margin) 0;
  position: relative;

  .article-footer__grid {
    @include grid-container;
  }

  .article-footer__wrapper {
    border-top: 1px solid $color-grey-light;
    grid-column: var(--layout-content);
    margin-right: auto;
    max-width: 32em;
    padding-top: 40px;
  }

  .link-list,
  .button-list,
  .article-footer__share-buttons {
    margin-bottom: 40px;
  }
}
