
// Flexible webfont base file
// NOTE: When using this file as a base, rename all $*-font variables.

$prevent-font-face-declarations: false !default;

// Variable font settings
$suisseintl-font-name: 'Suisse Intl Webfont';
$suisseintl-font-cleanname: 'suisseintl';
$suisseintl-font-path: '../fonts/' + $suisseintl-font-cleanname + '/';
$suisseintl-font-stack: $suisseintl-font-name, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$suisseintl-font-feature-settings: 'kern' 1, 'calt' 1, 'liga' 1, 'frac' 1, 'dlig' 0;

// Weight names and their real values
// Defined here, since these names can vary per font family. Foundries get up the the weirdest things sometimes.

// Reference:
// - 100: thin, hairline
// - 200 extra light, ultra light
// - 300 light
// - 400 normal, regular, book
// - 500 medium
// - 600 semibold, demibold
// - 700 bold
// - 800 extrabold, ultrabold
// - 900 black, heavy

// Reminder: Font style is not supposed to be selected by name (ie: 'black-italic'),
// this is done automatically by setting font-style italic. However, it's still in the
// list in order for the @font-face to work
$suisseintl-font-weights: (
  ultralight: (weight: 100, style: normal),
  ultralight-italic: (weight: 100, style: italic),
  thin: (weight: 200, style: normal),
  thin-italic: (weight: 200, style: italic),
  light: (weight: 300, style: normal),
  light-italic: (weight: 300, style: italic),
  regular: (weight: 400, style: normal),
  regular-italic: (weight: 400, style: italic),
  medium: (weight: 500, style: normal),
  medium-italic: (weight: 500, style: italic),
  semibold: (weight: 600, style: normal),
  semibold-italic: (weight: 600, style: italic),
  bold: (weight: 700, style: normal),
  bold-italic: (weight: 700, style: italic),
  // extrabold: (weight: 800, style: normal),
  // extrabold-italic: (weight: 800, style: italic),
  black: (weight: 900, style: normal),
  black-italic: (weight: 900, style: italic),
);

// Automatically map weights
@each $weight, $properties in $suisseintl-font-weights {
  $font-style: map-get($properties, style);
  $font-weight: map-get($properties, weight);

  @if not ($prevent-font-face-declarations) {
    @font-face {
      font-display: swap;
      font-family: $suisseintl-font-name;
      font-style: $font-style;
      font-weight: $font-weight;
      src: url($suisseintl-font-path + $suisseintl-font-cleanname + '-' + $weight + '.woff2') format('woff2');
    }
  }
}

// Font mixin whereby you can use the real font weights
@mixin font-suisseintl($font-weight: null, $font-size: null, $line-height: null, $font-style: null, $letter-spacing: null, $text-transform: null) {

  // Convert possible 'normal' font-weight strings to 'regular', which is the font's actual weight name
  @if (not $font-weight or $font-weight == 'normal') {
    $font-weight: 'regular';
  }

  // Look up the actual weight
  @if ($font-weight != 'normal') {
    @if not (map-get($suisseintl-font-weights, $font-weight)) {
      @error 'Variant "' + $font-weight + '" not found in "' + $suisseintl-font-name + '" definition.';
    }

    $mapped-weight: map-get($suisseintl-font-weights, $font-weight);
    $mapped-value-weight: map-get($mapped-weight, 'weight');
    $mapped-value-style: map-get($mapped-weight, 'style');

    @if ($mapped-value-weight) {
      $font-weight: $mapped-value-weight;
    }

    @if ($mapped-value-style) {
      $font-style: $mapped-value-style;
    }
  }

  // Set the basics
  font-family: $suisseintl-font-stack;
  font-feature-settings: $suisseintl-font-feature-settings;
  font-size-adjust: initial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  // Include styles if given
  @if ($font-size) {
    font-size: $font-size;
  }

  @if ($font-style) {
    font-style: $font-style;
  }

  @if ($font-weight) {
    font-weight: $font-weight;
  }

  @if ($line-height) {
    line-height: $line-height;
  }

  @if ($letter-spacing) {
    letter-spacing: $letter-spacing;
  }

  @if ($text-transform) {
    text-transform: $text-transform;
  }
}

// Given the weight name, get the real weight value. For example: 'black' returns 900.
@function get-suisseintl-weight($font-weight) {
  // Convert possible 'normal' font-weight strings to 'regular', which is the font's actual weight name
  @if not $font-weight or $font-weight == 'normal' or $font-weight == 'roman' {
    $font-weight: 'regular';
  }

  // Look up the actual weight
  @if not (map-get($suisseintl-font-weights, $font-weight)) {
    @error 'Variant "' + $font-weight + '" not found in "' + $suisseintl-font-name + '" definition.';
  }

  $mapped-weight: map-get($suisseintl-font-weights, $font-weight);
  $mapped-value-weight: map-get($mapped-weight, 'weight');

  @if ($mapped-value-weight) {
    $font-weight: $mapped-value-weight;
  }

  @return $font-weight;
}
