.menu-overlay {
  background-color: $color-white;
  bottom: 0;
  color: $color-black;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;

  html.has-js & {
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s $easing-change;
  }

  html.has-js.overlay-visible & {
    opacity: 1;
    pointer-events: auto;
  }

  html:not(.has-js).overlay-visible & {
    display: block;
  }

  .menu-overlay__grid,
  .menu-overlay__wrapper {
    height: 100%;
  }

  .menu-overlay__grid {
    @include grid-container;
  }

  .menu-overlay__wrapper {
    grid-column: var(--layout-full);
    position: relative;

    @include landscape {
      grid-column: column 3 / span 9;
    }

    @include notebook {
      grid-column: column 3 / span 6;
    }

    &.menu-bar {
      grid-column: var(--layout-full);
      padding: 0;

      @include landscape {
        @include grid-container;

        margin: 0;
        padding: 0;
      }
    }
  }

  .menu-overlay__columns {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 50px;
    padding-top: 50px;

    @include landscape {
      flex-direction: row;
      padding-top: 50px;
    }
  }

  .menu-overlay__scroll-container {
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 80px;
    position: fixed;
    top: 140px;
    width: 100%;
    z-index: 4;

    @include notebook {
      padding-bottom: 0;
      padding-top: 20px;
    }
  }

  .menu-overlay__scroll-object {
    height: 100%;
  }

  .menu-overlay__close {
    position: absolute;
    right: 0;
    top: 18px;

    @include landscape {
      grid-column: column 3 / span 2;
      position: relative;
      top: 22px;
    }

    @include desktop {
      grid-column: column 3 / span 3;
    }

    .button--close {
      .icon-button__title {
        font-weight: var(--font-weight-black);
        margin-right: 10px;
      }

      .button__icon {
        height: 27px;
        width: 27px;
      }
    }
  }

  .menu-overlay__menu-items {
    flex: unset;

    @include landscape {
      flex: 1 1 0;
    }

    .link {
      @include typography(introduction);

      display: block;
      font-weight: var(--font-weight-black);
      line-height: 30px;

      &:not(:last-child) {
        padding-bottom: 25px;
      }

      &:hover,
      &:focus {
        .link__span {
          color: $color-black;
        }
      }
    }

    &:hover,
    &:focus {
      .link__span {
        color: $color-grey-medium;
      }
    }
  }

  .menu-overlay__logo {
    padding-top: 15px;
  }

  .menu-overlay__links {
    flex: unset;
    margin-top: 60px;

    @include landscape {
      flex: 1 1 0;
      margin-top: 0;
    }

    .menu-overlay__links-title {
      @include typography(subheading);

      font-weight: var(--font-weight-semibold);
      margin-bottom: 15px;
    }

    .link {
      @include typography(introduction);

      display: block;
      font-weight: var(--font-weight-regular);
      line-height: 30px;
      padding: 5px 0;

      .link__span {
        text-decoration: none;
      }
    }
  }

  .menu-overlay__buttons--show-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 25px;

    a {
      margin-bottom: 15px;
      margin-right: 20px;
    }

    @include landscape {
      display: none;
    }
  }

  .menu-overlay__buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;

    a {
      margin-left: 10px;
    }

    @include landscape {
      align-self: start;
      gap: 20px;
      grid-column: column 6 / span 7;
      padding-top: 15px;

      a {
        margin-left: 0;
      }
    }

    @include notebook {
      grid-column: column 6 / span 3;
    }
  }

  .menu-overlay__preview-container {
    display: none;

    @include notebook {
      display: inline;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 30%;
      z-index: 5;
    }
  }

  .menu-overlay__preview-wrapper {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .menu-overlay__preview-link-card {
    inset: 0 0 0 0;
    opacity: 1;
    position: absolute;
    transition: opacity .5s ease-in-out;
  }

  .menu-preview--is-hidden {
    opacity: 0;
  }

  .icon-button__icon {
    display: inline-block;
    height: 34px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 34px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}
