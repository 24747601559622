
.article-audio-fragment {
  margin: var(--margin) 0;
  position: relative;

  .article-audio-fragment__grid {
    @include grid-container;
  }

  .article-audio-fragment__wrapper {
    grid-column: var(--layout-content);
    margin-right: auto;
    max-width: 32em;
    width: 100%;
  }

  .article-audio-fragment__title {
    @include typography(heading-5);
  }

  .article-audio-fragment__description {
    @include typography(paragraph);
  }
}
