@mixin button {
  @include typography(button);

  align-items: center;
  background-color: $color-yellow;
  border: 1px solid;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-weight: 400;
  justify-content: center;
  // max-width: min(100%, 800px);
  min-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: filter $easing-change;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

@mixin link {
  border: none;
  border-color: transparent;
  border-radius: 20px;
  color: $color-black;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: normal;
  height: 36px;
  line-height: 24px;
  min-height: auto;
  padding: 14px;
  text-decoration: underline;
  width: auto;
}

.datepicker {
  background: $color-yellow;

  label.button--calendar {
    @include button;

    margin-left: 10px;
    margin-top: 30px;
    position: relative;
    z-index: 1;

    @include portrait {
      margin-left: var(--grid-gutter-edge);
    }

    @include landscape {
      position: absolute;
    }

    input {
      display: none;
    }

    &:focus,
    &:hover {
      filter: brightness(.9);
    }
  }

  label.button--calendar--hidden {
    display: none;
  }

  .button--calendar-icon {
    height: 20px;
    margin-right: 12px;
    width: 20px;
  }

  .datepicker--inline {
    display: inline-block;
  }

  .datepicker--grid {
    @include grid-container;
  }

  .daterangepicker {
    display: inline-block;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;

    @media (min-width: 680px) {
      @include grid-container;
    }

    .drp-buttons {
      .applyBtn,
      .drp-selected {
        display: none;
      }

      .cancelBtn {
        @include button;

        display: none;

        &:focus,
        &:hover {
          filter: brightness(.9);
        }
      }

      .cancelBtn--visible {
        display: block;
        margin-left: auto;
        margin-right: 10px;
      }
    }

    .ranges {
      display: flex;
      flex-direction: row;
      grid-column: var(--layout-full);
      justify-content: center;
      order: 1;

      @include landscape {
        grid-column: column 4 / span 9;
        justify-content: flex-end;
        order: 2;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-right: -10px;
        padding: 0;
        row-gap: 20px;
      }

      li {
        margin: 0 10px;
      }

      li:first-child {
        @include link;

        display: inline;
        line-height: initial;
      }

      li:nth-child(n + 2) {
        @include button;

        line-height: initial;

        &:hover {
          filter: brightness(.9);
        }
      }

      // We assume this to be the custom range button which we shall hide
      li:last-child {
        display: none;
      }
    }

    .drp-calendar {
      grid-row-start: 2;

      &.left {
        grid-column-start: 0;
        order: 2;

        @include landscape {
          grid-column-start: 1;
          order: 3;
        }

        @include notebook {
          grid-column-start: 2;
        }
      }

      &.right {
        grid-column-start: 1;
        order: 3;

        @include portrait {
          grid-column-start: 7;
          order: 4;
        }

        @include landscape {
          grid-column-start: 7;
          order: 4;
        }

        @include notebook {
          grid-column-start: 6;
          order: 4;
        }
      }

      .active {
        background-color: $color-white;
        border: 1px solid $color-black;
        border-radius: 40px;
      }

      .in-range:not(.end-date):not(.start-date),
      .in-range.no-start,
      .in-range.no-end {
        background-color: $color-black;
        border: 1px solid $color-black;
        border-radius: 40px;
        color: $color-white;
      }

      .start-date:not(.no-start):not(.end-date):not(.in-range),
      .end-date:not(.no-end):not(.start-date) {
        background-color: $color-white;
        border: 1px solid $color-black;
        border-radius: 40px;
        color: $color-black;
      }

      .off {
        cursor: default;
        opacity: .5;
        pointer-events: none;
      }

      .prev {
        cursor: pointer;

        &::before {
          content: '<';
        }
      }

      .next {
        cursor: pointer;

        &::before {
          content: '>';
        }
      }

      tbody {
        cursor: pointer;
      }
    }

    .margin-calendar {
      margin-top: 20px;
    }

    .drp-calendar--hidden {
      display: none;
    }
  }

  table.table-condensed {
    @include typography(paragraph);

    font-size: 13px;
    height: 306px;
    margin: auto;
    width: 296px;

    td,
    th {
      height: 40px;
      text-align: center;
      width: 40px;
    }

    th {
      font-weight: 500;
    }

    select.yearselect,
    select.monthselect {
      @include typography(paragraph);

      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
