.card-image-story {
  width: 50vw;

  .card-image-story__wrapper {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .card-image-story__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 80px 20px;
    width: 100%;

    @include landscape {
      padding: 80px 65px;
    }

    &.card-image-story--blue {
      background-color: $color-blue;
    }

    &.card-image-story--yellow {
      background-color: $color-yellow;
    }

    &.card-image-story--green {
      background-color: $color-green;
    }

    &.card-image-story--red {
      background-color: $color-red;
    }
  }

  .card-image-story__content-wrapper {
    max-height: 100%;

    &.extra-padding {
      padding: 0;

      @include landscape {
        padding: 0 75px;
      }
    }
  }

  .card-image-story__quote {
    background-color: $color-white;
    color: $color-black;
    min-height: 100%;
    padding: 20px;

    @include landscape {
      padding: 60px 70px;
    }
  }

  .card-image-story__quote-text {
    @include typography(heading-4);

    font-size: 24px;
    font-weight: var(--font-weight-semibold);
    line-height: 1.2em;
    margin-bottom: 10px;
    max-height: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;

    @include landscape {
      font-size: 32px;
    }
  }

  .card-image-story__quote-author {
    @include typography(paragraph);

    font-size: 18px;
    max-height: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;

    @include landscape {
      font-size: 22px;
    }
  }

  .card-image-story__title {
    @include typography(heading-2);

    margin-bottom: 35px;
    text-align: right;
    user-select: none;
  }

  .card-image-story__text {
    @include typography(paragraph);

    font-weight: var(--font-weight-regular);
    line-height: 1.5em;
    max-height: 100%;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    user-select: none;
  }
}
