.image-header {
  background-color: transparent;
  padding-top: 35vh;
  position: relative;

  @include portrait {
    padding-top: 43.75vh;
  }

  @include landscape {
    padding-top: 56.875vh;
  }

  #viewerToolbar {
    bottom: calc(var(--grid-spacing) * -3);
    display: flex;
    flex-flow: column;
    height: 90px;
    position: absolute !important;
    right: 0;
    z-index: 10;

    @include landscape {
      bottom: calc(var(--grid-spacing) * -2.5);
      height: 120px;
    }
  }

  .toolbarItem {
    height: 30px;
    width: 30px;

    @include landscape {
      height: 40px;
      width: 40px;
    }
  }

  #zoom-in {
    content: url('/static/images/deepzoom/Plus.svg');
  }

  #zoom-out {
    content: url('/static/images/deepzoom/Minus.svg');
  }

  #full-page {
    content: url('/static/images/deepzoom/Fullscreen.svg');
  }

  .image-header__osd-wrapper {
    width: 100%;
  }

  &.image-header--dzi,
  .image-header__osd,
  .image-header__osd-wrapper {
    background-color: $color-grey-light;
    height: 50vh;
    margin-bottom: calc(var(--grid-spacing) * 4);
    padding-bottom: calc(var(--grid-spacing) * 1);
    padding-top: calc(var(--grid-spacing) * 1.5);

    @include portrait {
      height: 55vh;
    }

    @include landscape {
      height: 70vh;
      padding-bottom: calc(var(--grid-spacing) * 1);
      padding-top: calc(var(--grid-spacing) * 1.2);
    }
  }

  .picture--background,
  .video {
    height: 40vh;

    @include portrait {
      height: 50vh;
    }

    @include landscape {
      height: 65vh;
    }
  }

  .image-header__grid {
    @include grid-container;
  }

  .image-header__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: var(--layout-header-center);
    margin-right: auto;
    min-height: 145px;
    padding: 30px 25px 0;
    text-align: center;
    width: 100%;

    @include landscape {
      margin-left: 18%;
      width: 66%;
    }

    @include notebook {
      margin-left: -22%;
      min-height: 200px;
      padding: 50px 40px 0;
      width: 143%;
    }
  }

  .image-header__title {
    @include typography(heading-1);

    text-transform: uppercase;
  }

  .image-header__title-custom {
    @include typography(heading-1);

    text-transform: initial;
  }

  .image-header__post-title {
    @include typography(introduction);

    margin-top: 30px;
  }

  .image-header__header {
    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .image-header__introduction {
    @include typography(light-text);
  }

  .image-header__introduction-grid {
    @include grid-container;
  }

  .image-header__introduction-wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 12;
    margin-top: 40px;
    text-align: center;

    @include notebook {
      grid-column: column 4 / span 6;
    }
  }

  .image-header__video {
    left: 0;
    position: absolute;
    top: 0;

    .video__container {
      height: 100%;
      padding-bottom: initial;

      .video__video {
        object-fit: cover;
      }
    }
  }
}

@mixin image-header--clean {
  padding-top: 60px;
}

.image-header--clean {
  @include image-header--clean;
}
