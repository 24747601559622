// Base colors
$color-black: #000;
$color-white: #fff;
$color-grey-logo: #9b9ea2;

// Theme colors
$color-red: #ff4c4c;
$color-blue: #00a5d9;
$color-green: #33b812;
$color-yellow: #ffd200;

// Light theme colors
$color-light-red: #ffb7b7;
$color-light-blue: #a6dff2;
$color-light-green: #d7f3d0;
$color-light-yellow: #ffefa6;

// Darkened theme colors
$color-pink-dark: #f2026a;
$color-blue-dark: #11717e;
$color-green-dark: #16a25e;

// Greyscale colors
$color-grey-superdark: #2c2c2c;
$color-grey-extradark: #555;
$color-grey-dark: #545454;
$color-grey-medium: #bbb;
$color-grey-light: #e7e7e7;
$color-grey-extralight: #fafafa;

// Functional colors
$color-error: #ff4735;

// Transparencies
$transparency-overlay: rgba($color-white, .8);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-hit-target: 100;
$z-index-form-field-button: 10;
$z-index-menu-overlay: 2048;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
// :root {
// None yet
//--bezier-fancy: $bezier-fancy;
// }
