
.article-content {
  margin: var(--margin) 0;
  position: relative;

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: var(--layout-content);
    margin-right: auto;
    max-width: 32em;
  }
}
