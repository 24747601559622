.card-grid-home {
  position: relative;

  .card-grid-home__grid {
    background-color: $color-black;
    height: calc(100vh);
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    white-space: nowrap;
    width: 100%;
  }

  .card-grid-home__wrapper {
    background-color: $color-black;
    grid-column: column 1 / span 12;
    height: calc(100vh - 88px);
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
  }

  .card-grid-home__introduction {
    background: $color-yellow;
    display: inline-block;
    height: 100%;
    margin: 0px;
    margin-left: -4px;
    position: relative;
    transition: background-color 600ms, width 600ms;
    width: 50vw;
  }

  &.card-grid-home--red .card-grid-home__introduction {
    background: $color-red;
  }

  &.card-grid-home--yellow .card-grid-home__introduction {
    background: $color-yellow;
  }

  &.card-grid-home--blue .card-grid-home__introduction {
    background: $color-blue;
  }

  &.card-grid-home--green .card-grid-home__introduction {
    background: $color-green;
  }

  .card-grid-home__title {
    font-size: 72px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
    text-decoration: underline;
  }

  .card-grid-home__text {
    @include typography(paragraph);

    text-align: right;
    white-space: break-spaces;
  }

  .card-grid-home__cards {
    position: relative;

    .card-home {
      display: inline-block;
      transition: width .2s;
      width: 300px;

      &:hover {
        width: 700px;
      }
    }
  }

  .card-grid-home__card-wrapper {
    display: inline-block;
    height: 100%;
    margin: 0px;
    margin-left: -4px;
    position: relative;
    transition: background-color 600ms, width 600ms;
    width: 300px;
  }

  .card-grid-home__button {
    bottom: 0px;
    left: 0px;
    position: absolute;
  }

  .card-grid-home__content {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .card-grid-home__heading {
    @include typography(heading-2);

    margin-bottom: 35px;
    text-align: right;
    white-space: break-spaces;
  }

  .card-grid-home__scroll {
    @include typography(paragraph);

    margin-top: 60px;
    text-align: right;
    white-space: break-spaces;
  }

  .object-large {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  @include mobile-only {
    .card-grid-home__introduction {
      display: block;
      height: calc(100vh - 75px);
      margin-left: 0;
      scroll-snap-align: start;
      width: 100vw;
    }

    .card-grid-home__grid {
      height: auto;
      margin-top: 75px;
      overflow: scroll;
      scroll-snap-type: y mandatory;
      white-space: unset;
    }

    .object-large {
      background-attachment: fixed;
    }
  }
}
