.notification-bar {
  padding: var(--grid-spacing);
  pointer-events: none;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 3;

  &.notification-bar--visible {
    display: flex;
    justify-content: space-between;
    pointer-events: initial;
    visibility: visible;
  }

  @include landscape {
    align-items: center;
    flex-direction: row;
  }

  &.notification-bar-dark {
    background-color: $color-grey-superdark;
    color: $color-white;

    .button--close div {
      background-color: $color-white;
    }
  }

  &.notification-bar-yellow {
    background-color: $color-yellow;
    color: $color-black;

    .button--close div {
      background-color: $color-black;
    }
  }

  .notification-bar--text {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;

    @include landscape {
      align-items: center;
      flex-flow: row nowrap;
    }

    > div {
      display: flex;
      flex-direction: column;

      @include landscape {
        align-items: center;
        flex-flow: row nowrap;
      }
    }

    svg {
      height: 24px;
      margin-right: 10px;
      width: 24px;
    }

    .notification-title {
      @include typography(button);

      margin-right: 20px;
    }
  }

  .notification-bar--close {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;

    p {
      @include typography(caption, true, true);
    }

    @include landscape {
      align-items: center;
    }

    > *:not(.button--close) {
      display: none;

      @include landscape {
        display: block;
      }
    }

    .button--close {
      height: 25px;
      margin-left: 15px;
      position: relative;
      width: 25px;

      div {
        bottom: 0;
        height: 2px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 25px;
      }

      div:first-child {
        rotate: 45deg;
      }

      div:last-child {
        rotate: 135deg;
      }
    }
  }
}
