
.form-fieldset {
  @include adaptive-margin(45px, 45px);
  @include adaptive-padding(25px, 0);

  position: relative;

  // start border/<legend> nonsense
  &:not(:first-child) {
    border-top: 2px solid transparent;

    .form-fieldset__legend {
      border-top: 45px solid transparent; // due to border/<legend> nonsense
      margin-bottom: var(--heading-5-margin-bottom);
    }

    &::before {
      background: $color-black;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  // end border/<legend> nonsense

  > p {
    @include typography(caption, true, true);
  }

  .form-fieldset__legend {
    @include typography(heading-4, false, false, true);

    display: block;
    width: 100%;
  }
}
