
.meta-header {
  margin: var(--margin) 0;
  position: relative;

  .meta-header__grid {
    @include grid-container;
  }

  .meta-header__wrapper {
    grid-column: var(--layout-content);
    margin-right: auto;
  }

  h1 {
    @include typography(heading-4);

    margin-top: var(--grid-spacing);
  }

  h2 {
    @include typography(introduction);
  }
}
