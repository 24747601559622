.button {
  @include typography(button);

  align-items: center;
  border: 1px solid;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 800px);
  min-height: 48px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: background-color $easing-change;
  user-select: none;
  white-space: nowrap;
  width: auto;

  .button__span {
    align-self: center;
    display: block;
    flex: 1 1 100%;
    height: 20px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button__icon {
    display: block;
    flex: 0 0 var(--button-line-height);
    height: var(--button-line-height);
    padding: .1em .1em .2em;
    width: var(--button-line-height);

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .button__icon--before {
    align-self: center;
    margin-left: -.5em;
    margin-right: .5em;
  }

  .button__icon--after {
    margin-left: .5em;
    margin-right: -.5em;
  }
}

.button:disabled,
.button--disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.button--primary {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-grey-extradark;
  }
}

.button--secondary {
  border-color: $color-black;
  color: $color-black;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-grey-light;
  }
}

.button--tertiary {
  background-color: $color-blue;
  border-color: $color-black;
  color: $color-black;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-grey-light;
  }
}

.button--filter {
  border-color: $color-black;
  border-radius: 20px;
  color: $color-black;
  font-size: 14px;
  height: 36px;
  line-height: 24px;
  min-height: auto;
  padding: 14px;
  width: auto;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-blue;
  }

  &.button:disabled,
  &.button--disabled {
    opacity: 1;
  }
}

.button--filter-text {
  border: none;
  border-color: transparent;
  border-radius: 20px;
  color: $color-black;
  font-size: 14px;
  font-weight: normal;
  height: 36px;
  line-height: 24px;
  min-height: auto;
  padding: 14px;
  text-decoration: underline;
  width: auto;
}

.button--circular {
  align-content: center;
  background-color: rgba(0, 0, 0, .25);
  border: 1px solid $color-white;
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  height: 150px;
  justify-content: center;
  left: 50%;
  padding: 30px;
  position: relative;
  text-align: center;
  width: 150px;

  .circular-button-text {
    color: $color-white;
    text-decoration: underline;
    user-select: none;
  }

  .circular-button-icon-after {
    bottom: -30px;
    height: 70px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: transform .2s;
    width: 20px;
  }
}

.button--clean {
  align-items: center;
  border: none;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content;

  .button__icon {
    flex: 0 0 calc(1.2 * var(--button-line-height));
    height: calc(1.2 * var(--button-line-height));
    width: calc(1.2 * var(--button-line-height));
  }

  .button__icon--before,
  .button__icon--after {
    margin-left: 0;
    margin-right: 0;
  }
}
