.link {
  @include typography(paragraph);

  color: inherit;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  vertical-align: bottom;

  .link__span {
    display: inline-block;
    text-decoration: underline;
    transition: color $easing-change;
    vertical-align: baseline;
  }

  .link__icon {
    display: inline-block;
    height: var(--paragraph-line-height);
    padding: .1em .1em .2em;
    position: relative;
    vertical-align: top;
    width: var(--paragraph-line-height);

    > svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &.no-underline .link__span {
    text-decoration: none;
  }

  &.bold {
    align-items: center;
    display: flex;

    .link__span {
      @include typography(subheading);

      margin-left: 10px;
    }
  }

  &:hover,
  &:focus {
    .link__span {
      color: $color-grey-dark;
    }
  }
}
