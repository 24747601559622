:root {
  --clip-position-1: 50% 50%;
  --clip-position-2: 50% 50%;
}

.menu-bar {
  opacity: 1;
  padding: 15px 0;
  position: fixed;
  user-select: none;
  width: 100%;
  z-index: 3;

  &::after {
    background-image: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0));
    bottom: 0px;
    content: '';
    display: inline;
    height: 50vh;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: -1;
  }

  &.menu-bar--hidden {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &.menu-bar--default {
    background-color: $color-white;
  }

  &.menu-bar--bg-transparent {
    background-color: transparent;
  }

  &.menu-bar--white-text {
    color: $color-white;

    .logo {
      color: $color-white;
    }

    .button--primary {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-black;
    }

    .button--secondary {
      border-color: $color-white;
      color: $color-white;
    }

    .button--clean {
      color: $color-white;
    }

    &::after {
      opacity: 1;
      transition: background-image 1s;
    }
  }

  &.menu-bar--black-text {
    color: $color-black;
  }

  .menu-bar__grid {
    @include grid-container;
  }

  .menu-bar__wrapper {
    @include grid-container;

    flex-direction: row;
    grid-column: var(--layout-full);
    margin: 0;
    padding: 0;
  }

  .menu-bar__logo {
    grid-column: column 1 / span 2;
  }

  .menu-bar__links {
    display: none;

    @include notebook {
      align-items: center;
      display: flex;
      grid-column: column 4 / span 5;
      justify-content: space-evenly;
      text-align: right;
    }
  }

  .menu-bar__icon-button {
    align-self: center;
    position: absolute;
    right: var(--grid-gutter-edge);
    top: 18px;

    @include landscape {
      position: relative;
      right: 0;
      top: 0;
    }

    .icon-button__title {
      margin-right: 10px;
    }
  }

  .icon-button__title,
  .link {
    @include typography(button);

    font-weight: var(--font-weight-black);
    text-decoration: underline;
  }

  .menu-bar__buttons {
    color: $color-black;
    display: none;

    @include landscape {
      display: flex;
      flex: 0 1;
      gap: 20px;
      grid-column: column 9 / span 4;
      justify-content: flex-end;
    }
  }

  .icon-button__icon {
    display: inline-block;
    height: 34px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 34px;
  }

  .icon-button {
    .line {
      fill: none;
      stroke: currentColor;
      stroke-width: 7;
      transition: stroke-dasharray 500ms cubic-bezier(.4, 0, .2, 1), stroke-dashoffset 500ms cubic-bezier(.4, 0, .2, 1);
    }

    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 7;
    }

    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 7;
    }

    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 7;
    }

    &.opened {
      .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }

      .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
      }

      .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
    }
  }
}
