.article-video {
  padding: var(--component-margin) 0;
  position: relative;

  .article-video__grid {
    @include grid-container;
  }

  .article-video__wrapper {
    grid-column: var(--layout-full);

    @include landscape {
      grid-column: column 2 / span 10;
    }
  }
}
