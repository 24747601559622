
:root {
  --paragraph-font-size: 16px;
  --paragraph-font-stack: #{$suisseintl-font-stack};
  --paragraph-font-style: normal;
  --paragraph-font-variant: normal;
  --paragraph-font-weight: #{get-suisseintl-weight(light)};
  --paragraph-letter-spacing: 0;
  --paragraph-line-height: 1.45em;
  --paragraph-margin-bottom: .5em;
  --paragraph-margin-top: 1em;

  @include portrait {
    --paragraph-font-size: 17px;
    --paragraph-line-height: 1.4em;
  }

  @include landscape {
    --paragraph-font-size: 18px;
    --paragraph-line-height: 1.4em;
  }
}
