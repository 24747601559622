.card-content {
  align-self: start;
  color: inherit;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 100%;

  .card-content__image {
    overflow: hidden;
    position: relative;
    transition: transform 2s ease-out;
  }

  .card-content__content {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    position: relative;
  }

  .card-content__title {
    @include typography(heading-5);

    margin-bottom: var(--heading-5-margin-bottom);
  }

  .card-content__text {
    @include typography(paragraph);

    flex: 1 1 auto;
    padding-bottom: 8px;
  }

  &:hover,
  &:focus {
    .card-content__image {
      transform: scale(1.05);
    }
  }

  .icon-button__icon {
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 16px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}
