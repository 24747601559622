.logo {
  display: inline-block;
  height: 40px;
  line-height: 0;
  position: relative;
  transition: color $easing-change;
  user-select: none;
  vertical-align: top;
  width: 90px;

  &.logo--grey {
    color: $color-grey-logo;
  }

  &.logo--white {
    color: $color-white;
  }

  .logo__svg {
    display: block;
    height: 100%;
    width: 100%;

    path {
      fill: currentColor;
      transition: fill $easing-change;
    }
  }
}
