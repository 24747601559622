.card-grid-highlight {
  margin: var(--margin) 0;
  padding-bottom: 114px;
  padding-top: 71px;
  position: relative;

  @include landscape {
    padding-top: 114px;
  }

  &.card-grid-highlight-red {
    background-color: $color-red;
  }

  &.card-grid-highlight-yellow {
    background-color: $color-yellow;
  }

  &.card-grid-highlight-green {
    background-color: $color-green;
  }

  &.card-grid-highlight-blue {
    background-color: $color-blue;
  }

  .card-grid-highlight__grid {
    @include grid-container;
  }

  .card-grid-highlight__wrapper {
    grid-column: var(--layout-full);
  }

  .card-grid-highlight__cards {
    display: grid;
    grid-gap: 20px var(--grid-gutter);
    grid-template-columns: 1fr;

    @include landscape {
      grid-gap: 60px var(--grid-gutter);
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .card-grid-highlight__pagination {
    text-align: center;
  }

  .card-grid-highlight__content {
    margin-bottom: 62px;

    .card-grid-highlight__content-grid {
      @include grid-container;

      padding-left: 0px;
      padding-right: 0px;
    }

    .card-grid-highlight__content-wrapper {
      grid-column: column 2 / span 10;

      @include landscape {
        grid-column: column 4 / span 6;
      }
    }

    .card-grid-highlight__content-title {
      @include typography(heading-2);

      margin-bottom: var(--heading-2-margin-bottom);
      text-align: center;
    }

    .card-grid-highlight__content-text {
      @include typography(paragraph);

      text-align: center;
    }
  }
}
