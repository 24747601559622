.event-header {
  background-color: transparent;
  color: transparent;
  margin-bottom: 30px;
  position: relative;

  .event-header__image {
    height: 100vh;
    position: fixed;
    width: 100vw;

    .picture--background {
      height: 100%;
    }
  }

  .event-header__video {
    height: 100%;

    .video__container {
      height: 100%;

      .video__video {
        object-fit: cover;
      }
    }
  }

  .event-header__scroll-button {
    bottom: 0;
    position: absolute;
    transition: opacity .4s;
    width: 100%;

    .button--circular {
      bottom: 0;
      margin-bottom: 50px;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &:hover .circular-button-icon-after {
    transform: translateX(-50%) translateY(10px);
  }

  .event-header__grid {
    @include grid-container($full-width: true, $no-edges: true);

    padding-top: 100vh;
    position: relative;
  }

  .event-header__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 11;
    padding-bottom: 60px;
    width: 100%;

    @include grid-container($columns: 'repeat(8, [column] minmax(0, 1fr))', $full-width: true, $no-edges: true);

    @include notebook {
      grid-column: column 1 / span 8;
    }

    @include landscape {
      padding-bottom: 130px;
    }
  }

  .event-header__banner {
    align-items: center;
    background-color: $color-yellow;
    display: flex;
    font-weight: 900;
    grid-column: column 1 / span 8;
    height: 90px;
    justify-content: space-between;
    width: 100%;
  }

  .event-header__banner-title {
    padding-left: var(--grid-gutter-edge);
  }

  .event-header__banner-link {
    padding-right: var(--grid-gutter-edge);
  }

  .event-header__subwrapper {
    grid-column: column 2 / span 6;
    padding-top: 60px;

    @include landscape {
      padding-top: 130px;
    }
  }

  .event-header__category {
    border: 1px solid #000;
    border-radius: 40px;
    display: inline-block;
    font-size: 10px;
    height: 18px;
    letter-spacing: 3px;
    line-height: 14px;
    margin-bottom: 13px;
    overflow: hidden;
    padding: 3px 5px 3px 8px;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .event-header__title {
    @include typography(heading-2);

    text-decoration: none;

    @include landscape {
      font-size: 48px;
    }
  }

  .event-header__post-title {
    @include typography(light-text);

    color: #666;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 20px;
    margin-top: 20px;

    @include notebook {
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  .event-header__header {
    grid-column: column 2 / span 6;

    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .event-header__introduction {
    grid-column: column 2 / span 6;

    @include typography(introduction);
  }
}

@mixin event-header--clean {
  padding-top: 60px;
}

.event-header--clean {
  @include event-header--clean;
}
