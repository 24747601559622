.card-whatson-grid {
  margin: calc(var(--margin) * 1.5) 0;
  position: relative;

  .card-whatson-grid__pagination {
    padding: 40px 0;
    text-align: center;

    @include landscape {
      padding: 100px 0;
    }
  }

  .card-whatson-grid__grid {
    @include grid-container;

    padding: 0;

    @include landscape {
      padding-left: var(--grid-gutter-edge);
      padding-right: var(--grid-gutter-edge);
    }
  }

  .card-whatson-grid__wrapper {
    grid-column: column 1 / span 12;
  }

  .card-whatson-grid__title {
    @include typography(heading-4);

    font-weight: var(--font-weight-regular);
    margin-bottom: var(--heading-4-margin-bottom);
  }

  .card-whatson-grid__cards {
    display: grid;
    grid-gap: 20px var(--grid-gutter);
    grid-template-columns: 1fr;

    @include landscape {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 60px;
    }

    .card-whatson {
      align-self: start;
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  .card-whatson-grid__button {
    margin-top: var(--margin);
    text-align: center;
  }
}
