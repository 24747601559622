@import '../../../assets/stylesheets/utilities/mixins/fix-antialiasing';

// The edge stuff is because it crashes when accessing css variables from inside the html element.

html {
  @include fix-antialiasing; // Prevent smudgy bold fonts on OSX
  @include font-inter;

  &.is-edge {
    font-size: 20px;

    @include landscape {
      font-size: 24px;
    }
  }

  &:not(.is-edge) {
    @include typography(paragraph);
  }

  background-color: $color-white;
  color: $color-black;
  position: relative;
  scroll-behavior: smooth;
  //scroll-padding-top: calc(var(--menu-bar-height) + 30px);

  em,
  i {
    font-style: oblique;
  }

  strong,
  b {
    font-weight: var(--font-weight-semibold);
  }

  &.prevent-scrolling {
    max-height: 100vh;
    overflow: hidden;
  }

  &.is-ios {
    -webkit-overflow-scrolling: touch;
  }
}

body {
  html.is-edge & {
    @include typography(paragraph);
  }

  background-color: $color-white;
  color: $color-black;
  font: inherit;
  -webkit-font-smoothing: antialiased; // Prevent smudgy bold fonts on OSX
  margin: 0 auto;
  max-width: var(--document-max-width);
  min-width: var(--document-min-width);
  position: relative;
  width: 100%;
}
