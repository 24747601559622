
:root {
  --heading-5-font-size: 18px;
  --heading-5-font-stack: #{$suisseintl-font-stack};
  --heading-5-font-style: normal;
  --heading-5-font-variant: normal;
  --heading-5-font-weight: #{get-suisseintl-weight(black)};
  --heading-5-letter-spacing: 0;
  --heading-5-line-height: 1.4em;
  --heading-5-margin-bottom: .5em;
  --heading-5-margin-top: 1.75em;

  @include portrait {
    --heading-5-font-size: 18px;
    --heading-5-line-height: 1.35em;
  }

  @include landscape {
    --heading-5-font-size: 20px;
    --heading-5-line-height: 1.3em;
  }
}
