
.article-photo {
  margin: var(--margin) 0;
  position: relative;
}

.article-photo--align-default {
  .article-photo__grid {
    @include grid-container;
  }

  .article-photo__wrapper {
    grid-column: var(--layout-full);
    margin-right: auto;

    @include landscape {
      grid-column: column 2/ span 7;
    }
  }
}

.article-photo--align-full {
  .article-photo__grid {
    @include grid-container;
  }

  .article-photo__wrapper {
    grid-column: var(--layout-full);

    @include landscape {
      grid-column: column 2/ span 7;
    }
  }
}

.article-photo--align-fullbleed {
  .figure__caption {
    padding: 0 calc(var(--grid-gutter) + var(--grid-margin));
  }
}
