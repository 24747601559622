
:root {
  --light-text-font-size: 16px;
  --light-text-font-stack: #{$suisseintl-font-stack};
  --light-text-font-style: normal;
  --light-text-font-variant: normal;
  --light-text-font-weight: #{get-suisseintl-weight(light)};
  --light-text-letter-spacing: 0;
  --light-text-line-height: 1.5em;
  --light-text-margin-bottom: .5em;
  --light-text-margin-top: 1em;

  @include portrait {
    --light-text-font-size: 17px;
    --light-text-line-height: 1.5em;
  }

  @include landscape {
    --light-text-font-size: 20px;
    --light-text-line-height: 1.5em;
  }
}
