
.article-form {
  margin: var(--margin) 0;
  position: relative;

  .article-form__grid {
    @include grid-container;
  }

  .article-form__wrapper {
    grid-column: var(--layout-content);
    max-width: 800px;
  }
}
