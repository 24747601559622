.card-mosaic {
  margin: var(--margin) 0;
  position: relative;

  .card-mosaic__grid {
    @include grid-container;
  }

  .card-mosaic__wrapper {
    grid-column: column 1 / span 12;
    margin-left: calc(var(--grid-gutter) * -.5);
    margin-right: calc(var(--grid-gutter) * -.5);
  }

  .card-mosaic__subwrapper {
    grid-column: column 2 / span 8;
  }

  .column {
    float: left;
    padding: 0 calc(var(--grid-gutter) / 2);
  }

  .size-1of2 {
    width: 50%;
  }

  .size-1of3 {
    width: calc(100% / 3);
  }

  .size-1of4 {
    width: 25%;
  }
}

.card-mosaic-item {
  display: block;
  padding-bottom: var(--margin);

  .card-mosaic-item__text {
    color: $color-black;
    margin-top: var(--grid-spacing);

    h5,
    h5 .richtext,
    h5 .richtext p {
      @include typography(heading-5);
    }
  }
}
