.event-card-grid-overview {
  margin: var(--margin) 0;
  position: relative;

  .event-card-grid-overview__pagination {
    text-align: center;
  }

  .event-card-grid-overview__grid {
    @include grid-container($full-width: true, $no-edges: true);

    padding: 0;
  }

  .event-card-grid-overview__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 11;
    padding-bottom: 60px;
    padding-top: 60px;
    width: 100%;

    @include grid-container($columns: 'repeat(10, [column] minmax(0, 1fr))', $full-width: true, $no-edges: true);

    @include notebook {
      grid-column: column 1 / span 10;
    }

    @include landscape {
      padding-bottom: 130px;
      padding-top: 130px;
    }
  }

  .event-card-grid-overview__subwrapper {
    grid-column: column 2 / span 8;
  }

  .event-card-grid-overview__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .event-card-grid-overview__title {
    @include typography(heading-4);
  }

  .event-card-grid-overview__cards {
    display: grid;
    grid-gap: 0 var(--grid-gutter);
    grid-template-columns: 1fr;

    @include landscape {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(2, 1fr);
    }

    .card-overview {
      align-self: start;
      flex: 1 1 33.33%;
      justify-self: center;

      &:not(last-child) {
        margin-bottom: 20px;

        @include landscape {
          margin-bottom: 0;
        }
      }
    }
  }

  .event-card-grid-overview__button {
    margin-top: var(--margin);
    text-align: center;
  }
}
