.event-photo-carousel {
  margin: var(--margin) 0;
  position: relative;

  .event-photo-carousel__grid {
    @include grid-container($full-width: true, $no-edges: true);

    padding: 0;
  }

  .event-photo-carousel__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 11;
    padding-bottom: 65px;
    padding-top: 65px;

    @include notebook {
      grid-column: column 1 / span 12;
      margin-right: var(--grid-gutter-edge);
    }
  }

  .event-photo-carousel__image {
    .swiper-slide {
      height: auto;
      width: 285px;

      @include landscape {
        width: 675px;
      }

      .figure__caption {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .swiper-container > .swiper-wrapper {
    margin-left: var(--grid-gutter-edge);

    @include landscape {
      margin-left: calc(var(--grid-gutter) + var(--grid-column-width));
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: none;
    color: $color-white;
    // override default
    display: inline-block;
    height: 16px;
    width: 16px;
  }

  .swiper-button-prev {
    left: 10px;
    position: absolute;
    top: 50%;
    z-index: 1;

    @include landscape {
      left: 45px;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 1;

    @include landscape {
      right: 45px;
    }
  }

  .swiper-button-disabled {
    opacity: .4;
    pointer-events: none;
  }
}
