.footer-bar {
  position: relative;

  .footer-bar__background-red {
    background-color: $color-red;
  }

  .footer-bar__background-black {
    background-color: $color-black;
  }

  .footer-bar__subscription-grid {
    @include grid-container($full-width: false);

    background-color: $color-red;
    padding-bottom: 40px;
    padding-top: 40px;

    .footer-bar__subscription-text {
      align-self: center;
      grid-column: var(--layout-full);
      margin-bottom: 5px;

      @include landscape {
        grid-column: column 2 / span 5;
        margin-bottom: 0px;
      }
    }

    .footer-bar__form-wrapper {
      grid-column: column 1 / span 13;

      @include landscape {
        grid-column: span 5 / 12;
      }
    }

    .footer-bar__form-message {
      position: relative;
    }

    .footer-bar__subscription-message-container,
    .footer-bar__input {
      @include typography(paragraph);

      background-color: $color-white;
      border: 0px;
      color: inherit;
      display: inline-block;
      line-height: 32px;
      padding: 16px 45px 16px 20px;
      text-overflow: ellipsis;
      user-select: none;
      width: 100%;
    }

    .footer-bar__icon,
    .footer-bar__button-icon {
      border: 16px solid transparent;
      fill: currentColor;
      height: 64px;
      left: 8px;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 0;
      width: 48px;
      z-index: 1;
    }

    .footer-bar__icon-container,
    .footer-bar__button {
      border-radius: 0;
      color: inherit;
      height: 64px;
      margin: 0;
      margin-left: -64px;
      padding: 16px;
      position: absolute;
      right: 0;
      top: 0;
      width: 64px;
    }

    .subscription-message-container--hidden {
      display: none;
    }

    .footer-bar__text-field-wrapper {
      display: block;
      max-width: 100%;
      position: relative;
    }
  }

  .footer-bar__grid {
    @include grid-container($full-width: true);
    @include typography(caption);

    background-color: $color-black;
    color: $color-white;
    line-height: 20px;
    padding-bottom: 50px;
    padding-top: 50px;

    .link {
      &:hover,
      &:focus {
        .link__span {
          color: $color-grey-medium;
        }
      }
    }

    .icon-button {
      height: 24px;
      width: 24px;

      &:hover,
      &:focus {
        color: $color-grey-medium;
      }
    }

    .footer-bar__logo {
      grid-column: column 1 / span 12;
      margin-bottom: 70px;
    }

    .footer-bar__column-1 {
      grid-column: column 1 / span 12;
    }

    .footer-bar__column-2 {
      @include grid-container;

      grid-column: column 1 / span 12;
      margin: 15px 0px;
      padding: 0;

      .footer-bar__column-2-links {
        display: flex;
        flex-direction: column;
        row-gap: 11px;

        &.links-top {
          grid-column: column 1 / span 5;
          margin-bottom: 0;
        }

        &.links-bottom {
          grid-column: column 7 / span 5;
          margin-bottom: 0;
        }
      }
    }

    .footer-bar__column-3 {
      grid-column: column 1 / span 12;
      margin: 0 0 15px;

      .footer-bar__column-3-links {
        display: flex;
        flex-direction: column;
        row-gap: 11px;
      }
    }

    .footer-bar__column-4 {
      grid-column: column 1 / span 12;
      margin: 15px 0px;
    }

    .footer-bar__bottom-links {
      display: flex;
      flex-direction: column;
      grid-column: column 1 / span 12;
      margin: 15px 0px;
      row-gap: 11px;
    }

    .footer-bar__social-links {
      align-items: flex-end;
      column-gap: 27px;
      display: flex;
      grid-column: span 11;
      justify-content: flex-start;
      margin: 15px 0px;
    }

    @include landscape {
      padding-bottom: 70px;
      padding-top: 70px;

      .footer-bar__logo {
        grid-column: column 2 / span 11;
        margin-bottom: 0;
      }

      .footer-bar__column-1 {
        grid-column: column 3 / span 2;
      }

      .footer-bar__column-2 {
        display: inline-block;
        grid-column: column 6 / span 1;
        margin: 0;

        .footer-bar__column-2-links {
          display: flex;
          flex-direction: column;
          row-gap: 11px;

          &.links-top {
            margin-bottom: 47px;
          }
        }
      }

      .footer-bar__column-3 {
        display: inline-block;
        grid-column: column 7 / span 1;
        margin: 0;

        .footer-bar__column-3-links {
          display: flex;
          flex-direction: column;
          row-gap: 11px;

          &.links-top {
            margin-bottom: 47px;
          }
        }
      }

      .footer-bar__column-4 {
        display: inline-block;
        grid-column: column 10 / span 2;
        margin: 0;

        .button--tertiary {
          margin-bottom: 10px;
          width: 100%;
        }
      }

      .footer-bar__bottom-links {
        display: inline-block;
        grid-column: column 3/span 7;
        margin: 0;
        margin-top: 60px;

        .link {
          margin-right: 30px;
        }
      }

      .footer-bar__social-links {
        align-items: flex-end;
        grid-column: column 10 / span 2;
        justify-content: flex-end;
        margin-top: 60px;
      }
    }

    ul {
      padding-left: 0;
    }
  }
}
