.event-video {
  padding: var(--component-margin) 0;
  position: relative;

  .event-video__grid {
    @include grid-container($full-width: true, $no-edges: true);
  }

  .event-video__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 11;
    padding-bottom: 60px;
    padding-top: 60px;
    width: 100%;

    @include grid-container($columns: 'repeat(10, [column] minmax(0, 1fr))', $full-width: true, $no-edges: true);

    @include notebook {
      grid-column: column 1 / span 10;
    }

    @include landscape {
      padding-bottom: 110px;
      padding-top: 110px;
    }
  }

  .event-video__subwrapper {
    grid-column: column 2 / span 8;
  }

  .event-video__title {
    @include typography(paragraph);

    font-weight: bold;
    margin-bottom: 22px;
  }
}
