
.dropdown-field {
  position: relative;

  .dropdown-field__label {
    @include typography(field-label, false, true, false);

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  .dropdown-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .dropdown-field__input {
    @include typography(paragraph);

    background-color: $color-white;
    border: 1px solid currentColor;
    color: $color-grey-extradark;
    display: inline-block;
    flex: 1 1 100%;
    height: 64px;
    line-height: 32px;
    margin: 0;
    min-height: 64px;
    min-width: 64px;
    overflow: hidden;
    padding: 16px 48px 16px 20px;
    position: relative;
    transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
    vertical-align: bottom;
    white-space: nowrap;
    width: 100%;

    &:hover:not(:disabled) {
      background-color: $color-grey-extralight;
    }

    + .dropdown-field__button {
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: transparent;
        color: inherit;
      }
    }

    &:disabled {
      opacity: .4;
      pointer-events: none;
    }
  }

  .dropdown-field__icon {
    border: 16px solid transparent;
    border-left-width: 8px;
    border-right-width: 8px;
    fill: currentColor;
    height: 64px;
    line-height: 64px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 48px;
    z-index: 1;
  }

  &.dropdown-field--required {
    .dropdown-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.dropdown-field--required) {
    .dropdown-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.dropdown-field--error {
    .dropdown-field__error {
      @include typography(caption);

      color: $color-error;
      display: inline-block;
      max-width: 100%;
      width: auto;
    }

    .dropdown-field__input {
      border-color: $color-error;
    }
  }
}
