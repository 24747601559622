
.link-list {
  position: relative;

  .link-list__title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  &.simple a span {
    text-decoration: none;
  }
}

.link-list--horizontal {
  .link-list__links {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: -10px;
  }

  .link {
    flex-basis: auto;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.link-list--vertical {
  .link-list__links {
    display: flex;
    flex-direction: column;
  }

  .link {
    flex-basis: 100%;

    &:not(:last-child) {
      margin-bottom: var(--paragraph-margin-bottom);
    }
  }
}
