.card-shop {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  .card-shop__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 388px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 2s ease-out;
      width: 100%;
      z-index: 0;
    }
  }

  &:hover,
  &:focus {
    .card-shop__image .picture {
      transform: scale(1.05);
    }
  }

  .card-shop__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px 0;
    position: relative;
    //transition: background-color $easing-change, color $easing-change;
  }

  .card-shop__title {
    @include typography(paragraph);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  .card-shop__price {
    @include typography(paragraph);

    flex: 1 1 auto;
    font-weight: var(--font-weight-semibold);
  }

  // &:hover,
  // &:focus {
  //   .card-shop__content {
  //     background-color: $color-blue;
  //   }
  // }
}
