.story-video-embed {
  .story-video-embed__wrapper {
    height: 100vh;
    position: relative;
    width: 100vw;
  }

  .video-embed {
    height: 100%;
  }

  .video-embed__container {
    height: 100%;
  }

  .card-story {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: opacity ease .5s;
    z-index: 2;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    &.card-story--hidden {
      opacity: 0;
    }
  }

  .story-video-embed__back-button {
    left: 0;
    opacity: 1;
    position: absolute;
    transition: opacity ease .5s;
    z-index: 2;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    &.back-button--hidden {
      opacity: 0;
    }

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .button__span {
      @include typography(light-text);

      font-weight: var(--font-weight-semibold);
      height: auto;
    }

    .button__icon {
      margin-right: 16px;
    }
  }

  .story-video-embed__close-button {
    height: 65px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 79px;
    transition: opacity ease .5s;
    width: 65px;
    z-index: -1;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    &.close-button--visible {
      opacity: 1;
      z-index: 15;
    }

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 17px;

      .button__span {
        display: none;
      }
    }

    .button__icon {
      margin: 0;
    }
  }

  .story-video-embed__play-button {
    .button--circular {
      bottom: 50%;
      cursor: pointer;
      left: 50%;
      margin-bottom: 50px;
      position: absolute;
      transform: translateX(-75px);
      transition: all ease .5s;
      z-index: 1;

      @include landscape {
        animation: scale-button ease .6s;
        animation-delay: .8s;
        animation-iteration-count: 3;
        bottom: 0;
        padding: 40px;
      }

      &:hover {
        @include landscape {
          height: 160px;
          margin-bottom: 45px;
          padding: 50px;
          transform: translateX(-80px);
          width: 160px;
        }
      }
    }
  }

  .video-embed__iframe {
    &.iframe--hidden {
      opacity: 0;
      z-index: -1;
    }
  }
}

@keyframes scale-button {
  50% {
    height: 160px;
    margin-bottom: 45px;
    padding: 50px;
    transform: translateX(-80px);
    width: 160px;
  }
}
