
.article-quote {
  margin: calc(var(--margin) * 1.5) 0;
  position: relative;

  .article-quote__grid {
    @include grid-container;
  }

  .article-quote__wrapper {
    grid-column: column 2 / span 10;
    margin-left: auto;
    margin-right: auto;
    max-width: 36em;
  }

  .article-quote__text {
    @include typography(heading-3);

    border: 1px solid $color-grey-light;
    border-radius: 10px;
    font-style: oblique;
    font-weight: var(--font-weight-light);
    line-height: 1.5em;
    padding: 20px 50px;
    position: relative;
    text-align: center;

    svg {
      fill: $color-grey-light;
      height: 2em;
      left: 0;
      position: absolute;
      top: calc(20px + .2em);
      transform: translate3d(-50%, 0, 0);
      width: 2em;
    }
  }

  .article-quote__attribution {
    @include typography(caption);

    display: inline-block;
    float: right;
    margin: 10px 50px 0;
    max-width: 32em;
    text-align: right;
    vertical-align: top;
  }

  .article-quote__attribution-name {
    font-weight: var(--font-weight-semibold);
  }

  .article-quote__attribution-text {
    font-style: oblique;
  }
}
