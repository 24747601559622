.icon-button {
  @include typography(button);

  align-items: center;
  border: 0;
  color: currentColor;
  cursor: pointer;
  display: flex;
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  position: relative;
  text-decoration: underline;
  transition: color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: $color-grey-extradark;
  }

  .icon-button__icon {
    display: block;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}

.icon-button--animation-press {
  .icon-button__icon {
    transition: transform .1s;
  }

  &:hover,
  &:focus {
    .icon-button__icon {
      transform: scale(.8);
    }
  }
}
