.article-fact {
  margin: calc(var(--margin) * 1.5) 0;

  .article-fact__grid {
    @include grid-container;
  }

  .article-fact__wrapper {
    grid-column: column 1 / span 12;

    @include landscape {
      grid-column: column 2 / span 10;
    }
  }

  .article-fact__content {
    @include typography(heading-5);

    align-items: center;
    border: 1px solid $color-black;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    min-height: 70px;

    .article-fact__icon {
      color: $color-grey-logo;
      height: 34px;
      margin-left: 30px;
      margin-right: 15px;
    }

    .article-fact__text {
      flex: 1 1;
      margin: 15px 30px 10px 0;

      @include typography(heading-5);

      .article-fact__text-span {
        font-weight: var(--font-weight-light);
      }
    }
  }
}
