.wormhole {
  background-color: $color-grey-dark;
  display: inline-block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;

  .wormhole__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 500px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .wormhole__layer-1 {
    clip-path: ellipse(150px 150px at var(--clip-position-1));
    height: 100vh;
    position: absolute;
    width: 100vw;

    &.wormhole__startgrow {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-name: wormhole__anim--startgrow-1;
    }

    &.wormhole__endgrow {
      animation-duration: 600ms;
      animation-fill-mode: forwards;
      animation-name: wormhole__anim--endgrow-1;
    }

    &.wormhole__front {
      z-index: 1;
    }
  }

  .wormhole__layer-2 {
    clip-path: ellipse(1px 1px at var(--clip-position-2));
    height: 100vh;
    position: absolute;
    width: 100vw;

    &.wormhole__startgrow {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-name: wormhole__anim--startgrow-2;
    }

    &.wormhole__endgrow {
      animation-duration: 600ms;
      animation-fill-mode: forwards;
      animation-name: wormhole__anim--endgrow-2;
    }

    &.wormhole__front {
      z-index: 1;
    }
  }

  @keyframes wormhole__anim--endgrow-1 {
    0% {
      clip-path: ellipse(150px 150px at var(--clip-position-1));
    }

    100% {
      clip-path: ellipse(2000px 2000px at var(--clip-position-1));
    }
  }

  @keyframes wormhole__anim--endgrow-2 {
    0% {
      clip-path: ellipse(150px 150px at var(--clip-position-2));
    }

    100% {
      clip-path: ellipse(2000px 2000px at var(--clip-position-2));
    }
  }

  @keyframes wormhole__anim--startgrow-1 {
    0% {
      clip-path: ellipse(1px 1px at var(--clip-position-1));
    }

    100% {
      clip-path: ellipse(150px 150px at var(--clip-position-1));
    }
  }

  @keyframes wormhole__anim--startgrow-2 {
    0% {
      clip-path: ellipse(1px 1px at var(--clip-position-2));
    }

    100% {
      clip-path: ellipse(150px 150px at var(--clip-position-2));
    }
  }

  .wormhole__text {
    @include typography(heading-1);

    color: $color-white;
    display: inline-block;
    font-size: 92px;
    left: 50%;
    line-height: 80px;
    max-width: 720px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .wormhole__text.wormhole__text--black {
    color: $color-black;
  }

  .wormhole__text.wormhole__text--white {
    color: $color-white;
  }
}
