.card-membership {
  border: 1px solid $color-black;
  color: $color-black;
  //cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 700px;
  overflow: hidden;
  padding: 28px;
  position: relative;
  user-select: none;

  &.card-membership--white {
    background-color: $color-white;
  }

  &.card-membership--light-yellow {
    background-color: $color-light-yellow;
  }

  .card-membership__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-bottom: 20px;
    position: relative;
  }

  .card-membership__content-title {
    @include typography(heading-6);

    margin-bottom: 45px;
  }

  .card-membership__buttons-group {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  ul {
    padding-left: 5px;
  }
}
