.card-menu-overlay {
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  .card-menu-overlay__content {
    align-items: center;
    align-self: center;
    background-color: $color-white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 35px 27px 27px;
    position: absolute;
    width: 280px;
  }

  .card-menu-overlay__title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
    text-align: center;
  }

  .card-menu-overlay__text {
    @include typography(paragraph);

    margin-bottom: 13px;
    text-align: center;
  }

  // &:hover,
  // &:focus {
  //   .card-menu-overlay__content {
  //     background-color: $color-blue;
  //   }
  // }

  .icon-button__icon {
    display: inline-block;
    height: 24px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 24px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .card-menu-overlay__format {
    border: 1px solid $color-black;
    border-radius: 40px;
    font-size: 10px;
    letter-spacing: .3em;
    line-height: 13px;
    margin-bottom: 13px;
    padding: 3px 8px;
    text-transform: uppercase;
  }
}
