.card-grid-shop {
  margin: var(--margin) 0;
  position: relative;

  .card-grid-shop__pagination {
    text-align: center;
  }

  .card-grid-shop__grid {
    @include grid-container;
  }

  .card-grid-shop__wrapper {
    grid-column: column 2 / span 10;
  }

  .card-grid-shop__title {
    @include typography(heading-4);

    font-weight: 400;
    margin-bottom: var(--heading-4-margin-bottom);
  }

  .card-grid-shop__cards {
    display: grid;
    grid-gap: 20px var(--grid-gutter);
    grid-template-columns: 1fr;

    @include landscape {
      grid-gap: 60px var(--grid-gutter);
      grid-template-columns: repeat(3, 1fr);
    }

    .card-shop {
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  .card-grid-shop__button {
    margin-top: var(--margin);
    text-align: center;
  }
}
