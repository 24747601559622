.story-image-header {
  background-color: transparent;
  color: transparent;
  height: 100vh;
  position: relative;
  width: 100vw;

  .story-image-header__image {
    .picture--background {
      height: 100%;
    }
  }

  .story-image-header__scroll-button {
    bottom: 0;
    position: absolute;
    transition: opacity .4s;
    width: 100%;

    .button--circular {
      bottom: 0;
      margin-bottom: 50px;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &:hover .circular-button-icon-after {
    transform: translateX(-50%) translateY(10px);
  }

  .story-image-header__back-button {
    bottom: 300px;
    left: 0;
    position: absolute;
    transition: opacity ease .5s;
    z-index: 2;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .button__span {
      @include typography(light-text);

      font-weight: var(--font-weight-semibold);
      height: auto;
    }

    .button__icon {
      margin-right: 16px;
    }
  }

  .card-story {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: opacity ease .5s;
    z-index: 2;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    &.card-story--hidden {
      opacity: 0;
    }

    .card-story__content {
      padding: 43px 32px 60px;
    }
  }
}

@mixin story-image-header--clean {
  padding-top: 60px;
}

.story-image-header--clean {
  @include story-image-header--clean;
}
