
.card-grid-content {
  margin: var(--margin) 0;
  position: relative;

  .card-grid-content__grid {
    @include grid-container;
  }

  .card-grid-content__wrapper {
    grid-column: var(--layout-full);
  }

  .card-grid-content__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }

  .card-grid-content__cards {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 72px;
  }

  .card-grid-content__pagination {
    text-align: center;
  }

  @include landscape {
    .card-grid-content__cards {
      grid-gap: 60px var(--grid-gutter);
      grid-template-columns: unquote('repeat(2, 1fr)');
    }

    .card-grid-content__wrapper {
      grid-column: column 2 / span 10;
    }
  }
}
