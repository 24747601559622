.card-overview {
  color: inherit;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  .card-overview__image {
    overflow: hidden;
    position: relative;
    transition: transform 2s ease-out;

    &::before {
      content: '';
      display: block;
      padding-top: 500px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .card-overview__content {
    align-items: center;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 25px 30px 12px;
    position: relative;
  }

  .card-overview__title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
    text-align: center;
  }

  .card-overview__text {
    @include typography(paragraph);

    margin-bottom: 13px;
    text-align: center;
  }

  &:hover,
  &:focus {
    .card-overview__image {
      transform: scale(1.05);
    }
  }

  .icon-button__icon {
    color: $color-black;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 16px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .card-overview__format {
    border: 1px solid $color-black;
    border-radius: 40px;
    font-size: 10px;
    height: 18px;
    letter-spacing: 3px;
    line-height: 14px;
    margin-bottom: 18px;
    overflow: hidden;
    padding: 3px 5px 3px 8px;
    text-overflow: ellipsis;
    text-transform: uppercase;

    @include landscape {
      margin-bottom: 13px;
    }
  }

  .card-overview__date {
    color: rgba(0, 0, 0, .75);
    font-size: 14px;
    margin-bottom: 13px;
    margin-top: 3px;
  }
}
