.card-story {
  opacity: 1;
  transition: opacity ease .5s;
  width: 280px;

  .card-story__wrapper {
    background-color: $color-white;
    color: $color-black;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .card-story__content {
    margin: auto;
    overflow: hidden;
    padding: 50px 27px;
    text-align: center;
  }

  .card-story__label {
    border: 1px solid $color-black;
    border-radius: 40px;
    display: inline-flex;
    font-size: 10px;
    height: 18px;
    letter-spacing: 3px;
    line-height: 14px;
    margin-bottom: 16px;
    overflow: hidden;
    padding: 3px 5px 3px 8px;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .card-story__title {
    @include typography(heading-6);
  }

  .card-story__subtitle {
    @include typography(paragraph);

    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  @include mobile-only {
    .card-story__wrapper {
      background-attachment: fixed;
    }

    .card-story__content {
      height: auto;
    }
  }

  .card-story__location {
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  ul {
    padding: 0;
  }
}
