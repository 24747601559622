.draggable-card-list-card {
  color: $color-black;
  display: inline-block;
  height: 100%;
  margin: 0px;
  position: relative;
  transition: background-color 600ms ease, width 600ms ease;
  white-space: initial;
  width: 320px;

  @include mobile-only {
    display: block;
    height: calc(100vh - 75px);
    margin-left: 0;
    scroll-snap-align: start;
    width: 100vw;
  }

  .draggable-card-list-card__image {
    height: 100%;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 500px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .icon-button__icon {
    color: $color-black;
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 16px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .draggable-card-list-card__wrapper {
    background-color: $color-black;
    bottom: 0px;
    height: 100%;
    position: absolute;
    width: 100%;

    @include mobile-only {
      background-attachment: fixed;
    }
  }

  .draggable-card-list-card__content {
    background-color: $color-white;
    bottom: -150px;
    height: 300px;
    left: 50%;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    transition: width 600ms ease-in-out, bottom 600ms ease-in-out;
    width: 280px;

    @include mobile-only {
      bottom: 0px;
      height: auto;
    }
  }

  .draggable-card-list-card__label {
    border: 1px solid $color-black;
    border-radius: 40px;
    display: inline-flex;
    font-size: 10px;
    height: 18px;
    letter-spacing: 3px;
    line-height: 14px;
    margin-bottom: 13px;
    overflow: hidden;
    padding: 3px 5px 3px 8px;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .draggable-card-list-card__title {
    @include typography(heading-6);

    margin-bottom: 15px;
  }

  .draggable-card-list-card__date {
    margin-bottom: 15px;
  }

  .draggable-card-list-card__text {
    margin-bottom: 8px;
  }
}
