.filter-bar {
  margin: calc(var(--margin) / 3) 0 var(--margin);
  position: relative;
  z-index: 2;

  .filter-bar__grid {
    display: grid;
    padding: 30px 0 0;
    transition: background-color .2s;

    @include grid-container;

    &[aria-hidden='true'] {
      display: none;
    }
  }

  .filter-bar__filters {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-column: column 1 / span 12;

    @include landscape {
      grid-column: column 1 / span 9;
    }
  }

  .filter-bar__filters-controls {
    align-self: flex-start;
    flex-grow: 1;
  }

  .filter-bar__top-right-controls {
    align-items: flex-start;
    display: flex;
    grid-column: column 1 / span 12;
    margin-top: 20px;

    @include landscape {
      flex-direction: row-reverse;
      grid-column: column 10 / span 3;
      margin-top: 0;
    }

    .button--secondary {
      font-weight: 400;
      margin-top: -6px;
      opacity: 1;
      transition: background-color .3s ease-in;

      &:focus,
      &:hover {
        background-color: $color-yellow;
      }
    }

    .button-toggle--hidden {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    .button--close-date {
      margin-left: auto;
      margin-right: -20px;

      @include portrait {
        margin-right: 0;
      }
    }
  }

  @keyframes fadein {
    0% {
      display: grid;
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .filter-bar__filter-panel {
    background: $color-blue;
    display: none;
    left: 0;
    opacity: 0;
    top: 0;
    width: 100%;

    &[aria-hidden='false'] {
      animation: fadein .2s normal;
      display: block;
      opacity: 1;
    }

    .filter-bar__filter-categories {
      grid-column: column 1 / span 12;

      @include notebook {
        grid-column: column 1 / span 8;
      }
    }

    .filter-bar__filter-category {
      margin: 30px 0;

      @include landscape {
        margin: 50px 0;
      }

      &:first-of-type {
        margin: 30px 0;
      }
    }

    .filter-bar__filter-nav {
      grid-column: column 1 / span 12;
      margin: 0 0 30px;

      @include notebook {
        grid-column: column 12 / span 1;
        margin: 30px 0;
      }
    }

    .button.button--filter.button--selected {
      background-color: $color-white;

      .button__icon--before {
        height: 12px;
        padding: 0;
      }
    }
  }

  .filter-bar__filter-panel-grid {
    @include grid-container;
  }

  .filter-bar__filter-panel-close-button {
    grid-column: column 12 / span 1;
    grid-row: row 1 / span 1;
  }

  .filter-bar__filter-category-intro {
    display: block;
    font-weight: normal;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .filter-bar__filter-panel-toggle {
    .button__icon {
      margin-top: -4px;
    }

    .button__icon > svg {
      height: 14px;
      width: 14px;
    }
  }

  .filter-bar__date-panel {
    display: none;
    margin-top: 21px;

    &[aria-hidden='false'] {
      animation: fadein .2s normal;
      display: block;
    }
  }
}

.filter-bar__filter-category {
  $button-margin-h: 10px;

  .filter-bar__filter-category-title {
    @include typography(paragraph);

    margin-bottom: 5px;
  }

  .filter-bar__filter-category-buttons {
    margin-left: -$button-margin-h;

    @include mobile-only {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .button.button--filter {
    margin: 0 10px 10px 0;

    @include landscape {
      margin: 0 20px 12px 0;
    }

    .button__icon--before {
      height: 12px;
      padding: 0;
    }
  }
}
