
:root {
  --subheading-font-size: 16px;
  --subheading-font-stack: #{$suisseintl-font-stack};
  --subheading-font-style: normal;
  --subheading-font-variant: normal;
  --subheading-font-weight: #{get-suisseintl-weight(bold)};
  --subheading-letter-spacing: 0;
  --subheading-line-height: 1.45em;
  --subheading-margin-bottom: .5em;
  --subheading-margin-top: 1em;

  @include portrait {
    --subheading-font-size: 16px;
    --subheading-line-height: 1.4em;
  }

  @include landscape {
    --subheading-font-size: 16px;
    --subheading-line-height: 1.4em;
  }
}
