:root {
  --button-font-size: 14px;
  --button-font-stack: #{$suisseintl-font-stack};
  --button-font-style: normal;
  --button-font-variant: normal;
  --button-font-weight: #{get-suisseintl-weight(black)};
  --button-letter-spacing: 0;
  --button-line-height: 23px;
  --button-margin-bottom: 0;
  --button-margin-top: 0;

  @include portrait {
    //
  }

  @include landscape {
    //
  }
}
