
.card {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  .card__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .card__content {
    background-color: $color-grey-extralight;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;
    position: relative;
    transition: background-color $easing-change, color $easing-change;
  }

  .card__title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  .card__text {
    @include typography(paragraph);

    flex: 1 1 auto;
  }

  &:hover,
  &:focus {
    .card__content {
      background-color: $color-blue;
    }
  }
}
