.story-image-list {
  background-color: $color-black;
  display: flex;
  height: 100vh;
  position: relative;
  width: 100vw;

  .card-image-story {
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity .3s ease;
    width: 100%;
    z-index: 1;

    .picture {
      filter: brightness(.8);
    }

    @include landscape {
      height: auto;
      opacity: 1;
      position: relative;
      transition: none;
      width: 50vw;
    }

    &.card-image-story--visible {
      opacity: 1;
    }
  }

  .story-image-list__container {
    background-color: $color-black;
    display: flex;
    height: 100vh;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: opacity .5s ease;
    width: 100vw;

    &.story-image-list__container--visible {
      opacity: 1;
    }
  }

  .story-image-list__clickable-container {
    background-color: transparent;
    cursor: pointer;
    height: 90%;
    width: 100%;
    z-index: 2;
  }

  .story-image-list__back-button {
    bottom: 60px;
    left: 30px;
    position: absolute;
    z-index: 3;

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .button__span {
      @include typography(light-text);

      font-weight: var(--font-weight-semibold);
      height: auto;
    }

    .button__icon {
      margin-right: 16px;
    }
  }

  .story-image-list__progress-bar-wrapper {
    bottom: 27px;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .progress-bar-container {
    column-gap: 10px;
    justify-content: center;
    padding: 0 10px;
    position: absolute;
    width: 100%;

    @include landscape {
      column-gap: 18px;
      padding: 0 34px;
    }

    &[data-role='progress-bar-container-mobile'] {
      display: flex;

      @include landscape {
        display: none;
      }
    }

    &[data-role='progress-bar-container-desktop'] {
      display: none;

      @include landscape {
        display: flex;
      }
    }
  }

  .story-image-list__progress-bar {
    background-color: $color-white;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 1;
    height: 5px;
    opacity: .4;
    transition: opacity $easing-change;

    &:hover {
      opacity: 1;
    }

    @include landscape {
      height: 8px;
    }
  }

  .progress-bar--selected {
    opacity: 1;
  }
}
