.story-panorama {
  background-color: $color-black;
  height: 100vh;
  width: 100vw;

  .story-panorama__container {
    cursor: grab !important;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .card-story {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: opacity ease .5s;
    z-index: 2;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    &.card-story--hidden {
      opacity: 0;
    }

    .card-story__content {
      padding: 43px 32px 60px;
    }
  }

  .story-panorama__back-button {
    left: 0;
    position: absolute;
    transition: opacity ease .5s;
    visibility: hidden;
    z-index: 2;

    @include portrait {
      left: var(--grid-gutter-edge);
    }

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .button__span {
      @include typography(light-text);

      font-weight: var(--font-weight-semibold);
      height: auto;
    }

    .button__icon {
      margin-right: 16px;
    }
  }

  .story-panorama__drag-button {
    transition: opacity .4s;

    .button--circular {
      bottom: 50%;
      left: 50%;
      margin-bottom: 50px;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;

      @include landscape {
        bottom: 0;
      }
    }

    &.drag-button--hidden {
      opacity: 0;
    }
  }

  .story-panorama__image--hidden {
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}
