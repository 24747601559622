.pagination {
  align-items: center;
  display: inline-block;
  font-size: 0;
  height: 40px;
  justify-content: center;
  position: relative;

  .number-button--selected,
  .number-button--dots {
    pointer-events: none;
  }
}
