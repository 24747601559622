.event-content {
  margin: var(--margin) 0;
  position: relative;

  .event-content__grid {
    @include grid-container($full-width: true, $no-edges: true);
  }

  .event-content__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 11;
    padding-bottom: 60px;
    padding-top: 60px;
    width: 100%;

    @include grid-container($columns: 'repeat(8, [column] minmax(0, 1fr))', $full-width: true, $no-edges: true);

    @include notebook {
      grid-column: column 1 / span 8;
    }

    @include landscape {
      padding-bottom: 110px;
      padding-top: 110px;
    }
  }

  .event-content__subwrapper {
    grid-column: column 2 / span 6;
  }
}
