
@mixin logo-graveyard {
  @include mobile {
    .logo-graveyard__logos {
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .logo-graveyard__logo {
      margin: 2.5%;
      position: relative;

      &:last-child,
      &:last-of-type {
        margin-right: 0;
      }

      .picture {
        background: transparent;

        img {
          height: auto;
          max-height: 200px;
          max-width: 400px;
          width: auto;
        }
      }
    }

    .picture__img {
      box-shadow: none;
    }
  }

  @include mobile-only {
    .logo-graveyard__logo {
      //width: 47.5%;
    }
  }

  @include portrait {
    .logo-graveyard__logo {
      //width: 23.125%;
    }
  }
}

.logo-graveyard {
  @include logo-graveyard;

  .logo-graveyard__grid {
    @include grid-container;
  }

  .logo-graveyard__wrapper {
    grid-column: var(--layout-full);
  }
}
