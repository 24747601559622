
.card-grid {
  margin: var(--margin) 0;
  position: relative;

  .card-grid__grid {
    @include grid-container;
  }

  .card-grid__wrapper {
    grid-column: column 1 / span 12;
  }

  .card-grid__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    grid-template-columns: unquote('repeat(auto-fill, minmax(280px, 1fr))');

    .card {
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  .card-grid__pagination {
    text-align: center;
  }
}
