
.button-list {
  margin: var(--margin) 0;
  position: relative;

  @include grid-container;

  .button-list__title {
    @include typography(heading-6);

    margin-bottom: var(--heading-6-margin-bottom);
  }

  .button-list__buttons {
    grid-column: var(--layout-full);
    margin-bottom: -10px;

    @include landscape {
      grid-column: column 2 / span 10;
    }

    + .button-list__buttons {
      margin-top: 20px;
    }
  }
}

.button-list--horizontal {
  .button-list__buttons {
    display: flex;
    flex-flow: row wrap;
  }

  .button,
  .icon-button,
  .share-button {
    flex-basis: auto;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.button-list--vertical {
  .button-list__buttons {
    display: flex;
    flex-direction: column;
  }

  .button,
  .icon-button,
  .share-button {
    flex-basis: 100%;

    &:not(:last-child) {
      margin-bottom: var(--paragraph-margin-bottom);
    }
  }
}
