
:root {
  --heading-3-font-size: 24px;
  --heading-3-font-stack: #{$suisseintl-font-stack};
  --heading-3-font-style: normal;
  --heading-3-font-variant: normal;
  --heading-3-font-weight: #{get-suisseintl-weight(semibold)};
  --heading-3-letter-spacing: -.002em;
  --heading-3-line-height: 1.3em;
  --heading-3-margin-bottom: .5em;
  --heading-3-margin-top: 1.25em;

  @include portrait {
    --heading-3-font-size: 32px;
    --heading-3-line-height: 1.25em;
  }

  @include landscape {
    --heading-3-font-size: 48px;
    --heading-3-line-height: 1.2em;
  }
}
