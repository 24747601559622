.homepage-header {
  padding-bottom: 60px;
  position: relative;

  &.homepage-header--audience {
    .homepage-header__introduction {
      background: $color-yellow;
      display: inline-block;
      height: 100%;
      margin: 0px;
      margin-left: -4px;
      position: relative;
      transition: background-color 600ms, width 600ms;
      width: 50vw;

      @include mobile-only {
        margin: 0;
        width: 100vw;
      }
    }

    &.homepage-header--audience-color-red .homepage-header__introduction {
      background: $color-red;
    }

    &.homepage-header--audience-color-yellow .homepage-header__introduction {
      background: $color-yellow;
    }

    &.homepage-header--audience-color-blue .homepage-header__introduction {
      background: $color-blue;
    }

    &.homepage-header--audience-color-green .homepage-header__introduction {
      background: $color-green;
    }

    .homepage-header__title {
      font-size: 72px;
      font-weight: 400;
      margin-bottom: 30px;
      text-align: center;
      text-decoration: underline;
    }

    .homepage-header__text {
      @include typography(paragraph);

      text-align: right;
      white-space: break-spaces;
    }

    .homepage-header__content {
      left: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .homepage-header__heading {
      @include typography(heading-2);

      margin-bottom: 35px;
      text-align: right;
      white-space: break-spaces;
    }

    .homepage-header__scroll {
      @include typography(paragraph);

      margin-top: 60px;
      text-align: right;
      white-space: break-spaces;
    }
  }

  &::before {
    //content: ' ';
    //display: block;
    //padding-top: 200vh;

    @include mobile-only {
      content: '';
      padding-top: 0;
    }
  }

  //&::after {
  //  content: ' ';
  //  display: block;
  //  padding-bottom: 48px;
  //}

  .homepage-header__animated-intro {
    height: 200vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    .homepage-header__intro {
      height: 100vh;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}

.homepage-header__scrollable-container {
  display: inline-flex;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .draggable-card-list {
    height: 100vh;
    overflow-y: hidden;
    transition: margin .6s ease;

    @include mobile-only {
      height: auto;
    }

    &:hover {
      margin-left: -80px;

      @include mobile-only {
        margin-left: 0;
      }
    }

    .draggable-card-list-card {
      &:hover {
        width: 480px;

        @include mobile-only {
          width: 100%;
        }

        .draggable-card-list-card__content {
          bottom: 0;
        }
      }
    }
  }
}

.homepage-header__scrollable {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100vh;

  @include landscape {
    position: fixed;
  }

  &.scroll-smooth {
    scroll-behavior: smooth;
  }
}

.homepage-header__see-more-button,
.homepage-header__explore-button {
  left: 0;
  z-index: 1;
}

.homepage-header__see-more-button {
  top: calc(100vh - 48px);

  .homepage-header.homepage-header--passed & {
    display: none;
  }

  svg {
    animation: shake-horizontal-right ease .6s;
    animation-delay: .8s;
    animation-iteration-count: 3;
  }
}

.homepage-header__explore-button {
  bottom: 20px;
  left: 0;
  position: absolute;

  .homepage-header:not(.homepage-header--passed) & {
    display: none;
  }

  .homepage-header.homepage-header--sticky-explore & {
    bottom: auto;
    position: fixed;
    top: 78px;
  }

  svg {
    animation: shake-vertical-up ease .6s;
    animation-delay: .8s;
    animation-iteration-count: 3;
  }
}

.homepage-header-intro {
  height: 100vh;
  top: 0;
  width: 100vw;

  .homepage-header-intro__content {
    background-color: $color-black;
    height: 100%;
    opacity: 1;
    position: relative;
    transition: opacity .3s $easing-change;
    width: 100vw;
  }

  .homepage-header-intro__content-title,
  .homepage-header__cursor-content-title,
  .homepage-header__second-content-title {
    align-items: center;
    display: flex;
    grid-column: column 4 / span 8;
    height: 100%;
    justify-content: center;
    margin: auto;
    max-width: 825px;
    text-align: right;
    text-transform: uppercase;
    user-select: none;

    h1 {
      @include typography(heading-1);

      padding: 0 110px;
    }

    strong,
    b {
      font-weight: inherit;
    }

    &.title--white {
      color: $color-white;
    }

    &.title--black {
      color: $color-black;
    }
  }
}

@include mobile-only {
  .homepage-header__content-drag-indicator {
    display: none;
  }

  .homepage-header__scrollable-container {
    display: inline-block;
  }

  .draggable-card-list-card:nth-child(n + 5) {
    display: none;
  }

  .homepage-header__scrollable {
    display: inline;
    height: auto;
    position: relative !important;
  }

  .homepage-header__button {
    width: 100%;
    z-index: 2;

    .button {
      justify-content: center;
      width: 100%;

      .button__span {
        flex: 0 1 auto;
        font-size: 18px;
        text-decoration: underline;
        width: auto;
      }
    }
  }

  .homepage-header__cards-wrapper {
    height: auto;
    position: relative;
    transform: translateX(0);
  }

  .homepage-header__content-title,
  .homepage-header__cursor-content-title,
  .homepage-header__second-content-title {
    padding: 0 21px;
    text-align: right;
    text-transform: none;

    h1 {
      font-size: 60px;
      padding: 0;
    }

    strong,
    b {
      font-weight: var(--font-weight-bold);
    }
  }
}

.homepage-header__content,
.homepage-header__cursor,
.homepage-header__second-content {
  .picture__image {
    filter: brightness(.66) contrast(1.1);
  }
}

@keyframes shake {
  50% {
    transform: translateX(-5px);
  }
}

@keyframes ekahs {
  50% {
    transform: translateX(5px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake-vertical-down {
  50% {
    transform: translateY(5px);
  }
}

@keyframes shake-vertical-up {
  50% {
    transform: translateY(-5px);
  }
}

@keyframes shake-horizontal-right {
  50% {
    transform: translateX(5px);
  }
}
