.event-card-grid-shop {
  margin: var(--margin) 0;
  position: relative;

  .event-card-grid-shop__pagination {
    text-align: center;
  }

  .event-card-grid-shop__grid {
    @include grid-container($full-width: true, $no-edges: true);
  }

  .event-card-grid-shop__wrapper {
    background-color: $color-white;
    color: $color-black;
    grid-column: column 1 / span 11;
    padding-bottom: 60px;
    padding-top: 60px;
    width: 100%;

    @include grid-container($columns: 'repeat(10, [column] minmax(0, 1fr))', $full-width: true, $no-edges: true);

    @include notebook {
      grid-column: column 1 / span 10;
    }

    @include landscape {
      padding-bottom: 130px;
      padding-top: 130px;
    }
  }

  .event-card-grid-shop__subwrapper {
    grid-column: column 2 / span 8;
  }

  .event-card-grid-shop__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .event-card-grid-shop__title {
    @include typography(heading-4);

    font-weight: var(--font-weight-medium);
  }

  .event-card-grid-shop__cards {
    display: grid;
    grid-gap: 20px var(--grid-gutter);
    grid-template-columns: 1fr;

    @include landscape {
      grid-gap: 60px var(--grid-gutter);
      grid-template-columns: repeat(4, 1fr);
    }

    .card-shop {
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  .event-card-grid-shop__button {
    margin-top: var(--margin);
    text-align: center;
  }

  .card-shop {
    min-width: auto;
  }

  .card-shop .card-shop__image::before {
    padding-top: 300px;
  }
}
