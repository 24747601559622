.fadetrail {
  background-color: $color-grey-dark;
  display: inline-block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;

  .fadetrail__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 500px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .fadetrail__layer-1 {
    height: 100vh;
    position: absolute;
    width: 100vw;
  }

  .fadetrail__layer-2 {
    height: 100vh;
    mix-blend-mode: lighten;
    position: absolute;
    width: 100vw;
    z-index: 1;
  }

  .fadetrail__layer-3 {
    height: 100vh;
    opacity: 0;
    position: absolute;
    transition: opacity 1s;
    width: 100vw;
  }

  .fadetrail__visible {
    opacity: 1;
  }

  .fadetrail__mask {
    background-color: $color-black;
    height: 100vh;
    mix-blend-mode: multiply;
    position: absolute;
    transition: background-color 600ms;
    width: 100vw;
  }

  .fadetrail__cursor {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-name: cursortrail;
    background-color: $color-white;
    //filter: blur(5px);
    background-image: url('/static/images/mask.jpg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 300px;
    height: 400px;
    mix-blend-mode: lighten;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    width: 400px;
  }

  @keyframes cursortrail {
    0% {
      //height: 200px;
      opacity: 0;
      //width: 200px;
    }

    30% {
      //height: 400px;
      opacity: 1;
      //width: 400px;
    }

    100% {
      //height: 300px;
      opacity: 0;
      //width: 300px;
    }
  }

  .fadetrail__text {
    @include typography(heading-1);

    color: $color-white;
    display: inline-block;
    font-size: 92px;
    left: 50%;
    line-height: 80px;
    max-width: 720px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .fadetrail__text.fadetrail__text--black {
    color: $color-black;
  }

  .fadetrail__text.fadetrail__text--white {
    color: $color-white;
  }
}
