.number-button {
  @include typography(paragraph);

  border-bottom: 3px solid;
  border-bottom-color: transparent;
  color: $color-black;
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font-weight-regular);
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  transition: border-bottom-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;

  &.number-button--selected {
    border-bottom-color: $color-black;
  }

  &:hover,
  &:focus {
    border-bottom-color: $color-black;
  }

  .number-button__icon {
    display: block;
    height: 16px;
    width: 16px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}

.number-button--default {
  //
}

.number-button--clean {
  background-color: transparent;
  color: currentColor;

  // &:hover,
  // &:focus {
  //   background-color: transparent;
  //   color: $color-blue;
  // }
}

.number-button--previous,
.number-button--next {
  padding: 6px 0 14px;

  @include landscape {
    padding: 5px 0 15px;
  }
}
