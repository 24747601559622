.card-highlight {
  cursor: pointer;
  height: 530px;
  overflow: hidden;
  position: relative;
  user-select: none;

  @include landscape {
    height: 680px;
  }

  .card-highlight__image {
    .picture {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 2s ease-out;
      width: 100%;
      z-index: 0;
    }
  }

  &:hover,
  &:focus {
    .card-highlight__image .picture {
      transform: scale(1.05);
    }
  }

  .card-highlight__overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 61.46%);
    color: $color-white;
    height: 100%;
    padding: 34px 38px;
    position: absolute;
    width: 100%;

    .card-highlight__overlay-content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      width: 80%;

      .card-highlight__overlay-content-title {
        @include typography(heading-5);

        margin-bottom: var(--heading-5-margin-bottom);
      }

      .card-highlight__overlay-content-text {
        @include typography(light-text);

        flex: 1 1 auto;
        padding-bottom: 8px;
      }
    }

    .icon-button__icon {
      height: 16px;
      overflow: hidden;
      position: absolute;
      right: 38px;
      top: 34px;
      white-space: nowrap;
      width: 16px;

      > svg {
        display: block;
        fill: currentColor;
        height: 100%;
        width: 100%;
      }
    }
  }
}
