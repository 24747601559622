.card-list {
  margin: var(--margin) 0;
  position: relative;

  .card-list__grid {
    @include grid-container($full-width: true);

    padding-left: var(--grid-gutter-edge);
    padding-right: var(--grid-gutter-edge);

    @include landscape {
      padding: 0;
    }
  }

  .card-list__wrapper {
    grid-column: var(--layout-full);
  }

  .card-list__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }

  .card-list__cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-height: 560px;

    @include landscape {
      display: flex;
      flex-direction: row;
      height: 360px;
      margin-bottom: 10px;
      width: 100%;
    }

    &:hover,
    &:focus {
      .card-list__image .picture {
        transform: scale(1.05);
      }
    }
  }

  .card-list__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 360px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 2s ease-out;
      width: 100%;
      z-index: 0;
    }

    @include landscape {
      min-width: 58%;
      width: 58%;

      .picture {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
      }
    }
  }

  .card-list__content {
    background-color: $color-grey-superdark;
    color: $color-white;
    padding: 30px;
    position: relative;
    width: 100%;

    @include notebook {
      padding-right: 150px;
    }
  }

  .card-list__card-title {
    @include typography(heading-4);

    margin-bottom: var(--heading-4-margin-bottom);
  }

  .card-list__text {
    @include typography(paragraph);

    margin-bottom: 8px;
  }

  .card-list__pagination {
    text-align: center;
  }

  .icon-button__icon {
    display: inline-block;
    height: 16px;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;
    white-space: nowrap;
    width: 16px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}
