.card-grid-overview {
  margin: var(--margin) 0;
  position: relative;

  .card-grid-overview__pagination {
    text-align: center;
  }

  .card-grid-overview__grid {
    @include grid-container;

    padding: 0;
  }

  .card-grid-overview__wrapper {
    grid-column: column 1 / span 12;
  }

  .card-grid-overview__title {
    @include typography(heading-4);

    font-weight: var(--font-weight-regular);
    margin-bottom: var(--heading-4-margin-bottom);
  }

  .card-grid-overview__cards {
    display: grid;
    grid-gap: 60px 0;
    grid-template-columns: 1fr;

    @include landscape {
      grid-template-columns: repeat(3, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(4, 1fr);
    }

    .card-overview {
      align-self: start;
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  .card-grid-overview__button {
    margin-top: var(--margin);
    text-align: center;
  }
}
