.drag-indicator {
  background-color: $color-black;
  border-radius: 20px;
  bottom: 10%;
  color: $color-white;
  cursor: grab;
  display: inline-flex;
  flex-direction: row;
  left: calc(50% - 100px);
  opacity: 0;
  padding: 12px 20px 13px;
  position: absolute;
  text-align: center;
  transition: opacity .5s $easing-change;
  width: 200px;
  z-index: 1;

  &.drag-indicator--visible {
    animation: slide-in ease-out 800ms;
    animation-iteration-count: 1;
    opacity: .85;

    svg {
      &:first-child {
        animation: shake ease .6s;
        animation-delay: .8s;
        animation-iteration-count: 2;
      }

      &:last-child {
        animation: ekahs ease .6s;
        animation-delay: .8s;
        animation-iteration-count: 2;
      }
    }
  }

  span {
    margin: auto;
  }

  .drag-indicator-icon {
    height: 16px;
    margin: auto;
    width: 16px;
  }
}
